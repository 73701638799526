import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LiveTracking from './components/LiveTracking';
import PullApiTracking from './components/PullApiTracking';

const Router = () => {
  return (
    <BrowserRouter>
     <Routes>
        <Route path="/livetracking/*" element={<LiveTracking/>} />
        <Route path="/pullapitracking/*" element={<PullApiTracking/>} />
    </Routes>
    </BrowserRouter>
  );
};

export default Router;