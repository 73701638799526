import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import L from "leaflet";
import "./PullApiTracking.css";
import { validateTrackingUrl } from "../Services";
import StartMarker from "../assets/start-marker.png";
import axios from "axios";
import LatLonSpherical from "./map-conv/index";

const MarkerIcon = L.icon({
  iconSize: [35, 40],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: StartMarker,
});

const LeafLetMapIconCa = L.divIcon({
  iconSize: [30, 40],
  html: '<div class="mapMarker"></div>',
});

const PullApiTracking = () => {
  const [IMEI, setIMEI] = useState("");
  const location = useLocation();
  const locationPaths = JSON.parse(sessionStorage.getItem("Live_Path")) || [];
  const [urlData, setUrlData] = useState(null);
  const formatedPaths = locationPaths?.map((i) => {
    return [i.latitude, i.longitude];
  });
  const liveLoc = JSON.parse(sessionStorage.getItem("Live_Location")) || {
    latitude: 17.43460111111111,
    longitude: 78.38339555555555,
  };
  const [latestLocation, setLatestLocation] = useState([
    liveLoc.latitude,
    liveLoc.longitude,
  ]);
  const url = location;
  const [isValidTrackingSession, setIsValidTrackingSession] = useState(false);
  const [center, setCenter] = useState([17.43460111111111, 78.38339555555555]);

  function MapCentering() {
    const map = useMap();
    if (center) {
      map.flyTo(center, map.getZoom());
    }
  }

  const checkValidTracking = async () => {
    const trackingId = location.pathname.slice(
      url.pathname.lastIndexOf("/pullapitracking") + 17
    );
    const hostUrl =
      process.env.REACT_APP_API_HOST + "/api/v4/pullapitracking/validate";
    const response = await validateTrackingUrl(trackingId, hostUrl);
    setUrlData(response.data);
    if (response && response.data && response.data.status) {
      if (response.data?.status === "SUCCESS") {
        setIsValidTrackingSession(true);
        setIMEI(response?.data?.regno);
        return true;
      }
      return false;
    }
    return false;
  };

  const getTrackingHistory = async () => {
    const trackingId = location.pathname.slice(
      url.pathname.lastIndexOf("/pullapitracking") + 17
    );
    const hostUrl =
      process.env.REACT_APP_API_HOST + "/api/v4/pullapitracking/history";
    try {
      const response = await axios.get(hostUrl + `/${trackingId}`);
      if (response && response.data) {
        let previousPoint = response.data[0];
        const historyPoints = response.data
          .map((i) => {
            const currentPoint = i;
            let distance = 0;
            if (
              previousPoint?.latitude &&
              previousPoint?.longitude &&
              currentPoint?.latitude &&
              currentPoint?.longitude
            ) {
              distance = new LatLonSpherical(
                previousPoint.latitude,
                previousPoint.longitude
              ).distanceTo(
                new LatLonSpherical(
                  currentPoint.latitude,
                  currentPoint.longitude
                )
              );
              i.rotation = new LatLonSpherical(
                previousPoint.latitude,
                previousPoint.longitude
              ).initialBearingTo(
                new LatLonSpherical(
                  currentPoint.latitude,
                  currentPoint.longitude
                )
              );
              i.distance = distance;
            } else {
              i.distance = 0;
            }
            previousPoint = currentPoint;
            return i;
          })
          .filter((i) => i.distance > 100);
        sessionStorage.setItem("Live_Path", JSON.stringify(historyPoints));
        return historyPoints;
      } else {
        sessionStorage.setItem("Live_Path", JSON.stringify([]));
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const isValid = checkValidTracking();
    if (isValid) {
      getTrackingHistory();
    }
  }, []);

  useEffect(() => {
    let socket = null;
    const trackingURL = location.pathname.slice(
      url.pathname.lastIndexOf("/pullapitracking") + 17
    );
    const string = trackingURL.slice(0, 7);
    const imei = IMEI;
    socket = io(process.env.REACT_APP_SOCKET_SERVER_URL);
    if (isValidTrackingSession && imei.length > 0) {
      socket.on("connect", () => {
        socket.emit("message", `${string}-${imei}`);
      });

      socket.on(`${string}-${imei}`, (data) => {
        try {
          const data1 = JSON.parse(data);
          const newLivePath =
            JSON.parse(sessionStorage.getItem("Live_Path")) || [];
          sessionStorage.setItem(
            "Live_Path",
            JSON.stringify([...newLivePath, data1])
          );
          sessionStorage.setItem("Last_Location", JSON.stringify(data));
          const { latitude, longitude } = data1;

          setCenter([latitude, longitude]);
        } catch (e) {
          console.log(`Error parsing JSON: ${e}`);
        }
      });

      return () => {
        socket.close();
      };
    }
  }, [isValidTrackingSession]);

  const polylineColor = {
    color: "rgb(26 131 175 / 80%)",
    fillOpacity: 0.01,
    weight: 7,
  };

  useEffect(() => {
    document.title = "SimplyTrack Live Location";
  }, []);

  return (
    <>
      {isValidTrackingSession ? (
        <MapContainer
          center={latestLocation}
          scrollWheelZoom={true}
          style={{
            height: "100vh",
            width: "100%",
          }}
          zoom={13}
        >
          <MapCentering />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {formatedPaths && formatedPaths[0] && (
            <Marker
              center={formatedPaths[0]}
              position={formatedPaths[0]}
              icon={MarkerIcon}
            />
          )}
          {formatedPaths?.length > 0 && (
            <Polyline positions={formatedPaths} pathOptions={polylineColor} />
          )}
          {formatedPaths && formatedPaths[formatedPaths?.length - 1] && (
            <Marker
              center={formatedPaths[formatedPaths?.length - 1]}
              position={formatedPaths[formatedPaths?.length - 1]}
              icon={LeafLetMapIconCa}
            />
          )}
        </MapContainer>
      ) : (
        <h1>Link got Expired </h1>
      )}
    </>
  );
};

export default PullApiTracking;
